<template>
  <div class="view view--user">
    <div class="view-header">
      <div class="view-header__footer">
        <Btn
          class="back-btn"
          :to="{ name: 'users' }"
          round
          grow
          icon="arrow-left"
        />
        <h1 class="page-title" v-html="`${data.prenom} ${data.nom}`"></h1>
      </div>
    </div>

    <div class="view-body">
      <Section>
        <Container>
          <div class="grid">
            <div class="tmp-col">
              <UserAvatar
                :initials="helperService.userInitials(data.prenom, data.nom)"
                :image="data.avatar ?? null"
                :color="data.couleur"
                size="large"
                class="h-margin-auto"
              />
            </div>
            <div class="tmp-col col-order-first">
              <KeyValue label="Prénom" :value="data.prenom" />
              <KeyValue label="Nom" :value="data.nom" />
              <KeyValue label="Identifiant" :value="data.identifiant" />
              <KeyValue label="Email" :value="data.email" />
              <KeyValue label="Téléphone" :value="data.telportable" />
            </div>
            <div class="tmp-col col-order-first">
              <KeyValue
                label="Entité"
                :value="data.entite && data.entite.nom"
              />
              <KeyValue label="Profils de droit">
                <div class="tags" v-if="data.profils">
                  <Tag
                    v-for="profil in data.profils"
                    :key="profil.id"
                    :id="profil.uid"
                    :text="profil.designation"
                  />
                </div>
              </KeyValue>
              <KeyValue label="Actif" :checked="data.actif" type="checkbox" />
            </div>
          </div>

          <!-- <pre><code>{{ JSON.stringify(data, null, 2) }}</code></pre> -->
        </Container>
      </Section>
    </div>

    <div class="view-footer">
      <div class="view-actions">
        <Btn
          v-if="
            this.helperService.userHasPermission(
              `utilisateur_edit_full,utilisateur_edit_entite`,
              'OR'
            )
          "
          :to="{ name: 'userEdit', params: { userid: userid } }"
          class="action-btn"
          text="Modifier l'utilisateur"
          icon="create"
          color="primary"
        />
      </div>
    </div>
  </div>

  <!-- Tool menu -->
  <ToolMenu>
    <MenuItem
      v-if="
        !itsMe &&
        this.helperService.userHasPermission(
          `utilisateur_remove_full,utilisateur_remove_entite,
                  utilisateur_edit_full,utilisateur_edit_entite`,
          'OR'
        )
      "
      :item-data="{
        label: 'Supprimer',
        iconName: 'trash-alt',
      }"
      @click="modalDeleteUser = true"
    />
    <MenuItem
      v-if="
        !itsMe &&
        this.helperService.userHasPermission(
          `utilisateur_edit_full,utilisateur_edit_entite`,
          'OR'
        )
      "
      :item-data="{
        label: 'Désactiver',
        iconName: 'user-times',
      }"
      @click="modalDisableUser = true"
    />
    <MenuItem
      v-if="
        !itsMe &&
        this.helperService.userHasPermission(
          `utilisateur_edit_full,utilisateur_edit_entite`,
          'OR'
        )
      "
      :item-data="{
        label: 'Ré-initialiser le mot de passe',
        iconName: 'key',
      }"
      @click="modalReinitUserPwd = true"
    />
    <MenuItem
      v-if="this.helperService.userHasPermission(`log_list`)"
      :item-data="{
        label: 'Voir les logs',
        route: {
          name: 'ressourceLogs',
          params: {
            ressource: 'utilisateur',
            rid: 'user',
            ressourceid: data.id,
            from: 'users',
            title: `Consulter les logs de ${data.prenom} ${data.nom}`,
          },
        },
        iconName: 'clipboard-list',
      }"
    />
    <MenuItem
      v-if="this.helperService.userHasPermission(`profil_show`)"
      :item-data="{
        label: 'Droits',
        route: { name: 'userPermissions' },
        iconName: 'lock',
      }"
    />
  </ToolMenu>

  <!-- Modals modalDeleteUser-->
  <Modal
    title="Supprimer l'utilisateur&nbsp;?"
    :active="modalDeleteUser"
    :data="data"
    @modal-close="modalDeleteUser = false"
  >
    <template v-slot:modal-body="{ data }">
      <p>
        Voulez vous vraiment supprimer l'utiisateur
        <b>{{ data.prenom }} {{ data.nom }}</b
        >&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modalDeleteUser = false" />
      <Btn
        text="Supprimer"
        @click="deleteUser()"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>

  <!-- Modals modalReinitUserPwd-->
  <Modal
    title="Ré-initialiser le mot de passe"
    :active="modalReinitUserPwd"
    :data="data"
    @modal-close="modalReinitUserPwd = false"
  >
    <template v-slot:modal-body="{ data }">
      <p>
        Voulez vous vraiment envoyer une demande ré-initialisation de mot de
        passe à
        <b>{{ data.prenom }} {{ data.nom }}</b
        >&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modalReinitUserPwd = false" />
      <Btn text="Envoyer" @click="reinitPwd()" icon="key" color="primary" />
    </template>
  </Modal>

  <!-- Modals modalDisableUser-->
  <Modal
    title="Désactiver"
    :active="modalDisableUser"
    :data="data"
    @modal-close="modalDisableUser = false"
  >
    <template v-slot:modal-body="{ data }">
      <p>
        Voulez vous vraiment désactiver <b>{{ data.prenom }} {{ data.nom }}</b
        >&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modalDisableUser = false" />
      <Btn
        text="Désactiver"
        @click="disableUser()"
        icon="user-times"
        color="primary"
      />
    </template>
  </Modal>
</template>

<script>
import UserAvatar from '@/components/user/UserAvatar.vue'
import KeyValue from '@/components/form/KeyValue.vue'
import Btn from '@/components/base/Btn.vue'
import Tag from '@/components/base/Tag.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'

import ToolMenu from '@/components/layout/ToolMenu.vue'
import MenuItem from '@/components/layout/MenuItem.vue'
import Modal from '@/components/layout/Modal.vue'

export default {
  name: 'UserView',

  components: {
    UserAvatar,
    KeyValue,
    Btn,
    Tag,
    Section,
    Container,
    ToolMenu,
    MenuItem,
    Modal,
  },

  data() {
    return {
      data: [],
      userid: null,
      modalDeleteUser: false,
      modalReinitUserPwd: false,
      modalDisableUser: false,
      itsMe: false,
    }
  },

  created() {
    this.userid = Number(this.$route.params.userid)
    this.itsMe = this.userid === this.$store.state.auth.user.data.id
  },

  mounted() {
    this.getData()
  },

  methods: {
    getData() {
      return this.fetchService
        .get(`utilisateur/${this.userid}`, {})
        .then((response) => {
          this.data = response.data
        })
    },
    deleteUser() {
      this.emitter.emit('open-loader')
      this.fetchService.delete(`utilisateur/${this.userid}`).then(
        () => {
          this.emitter.emit('alert', {
            type: 'success',
            content: "L'utilisateur a bien été supprimé.",
          })
          this.$router.push({ name: 'home' })
          this.emitter.emit('close-loader')
        },
        (responseError) => {
          this.emitter.emit('alert', {
            type: 'error',
            content: responseError.data,
          })
          this.emitter.emit('close-loader')
        },
      )
      this.modalDeleteUser = false
    },
    reinitPwd() {
      this.emitter.emit('open-loader')
      this.fetchService.get(`utilisateur/${this.userid}/mdp`).then(
        (response) => {
          this.emitter.emit('alert', {
            type: 'success',
            content: response.data,
          })
          this.emitter.emit('close-loader')
        },
        (responseError) => {
          this.emitter.emit('alert', {
            type: 'error',
            content: responseError.data,
          })
          this.emitter.emit('close-loader')
        },
      )
      this.modalReinitUserPwd = false
    },
    disableUser() {
      this.emitter.emit('open-loader')

      this.data.actif = false
      this.data.entite_id = this.data.entite.id

      this.fetchService.put(`utilisateur/${this.userid}`, this.data).then(
        () => {
          this.getData()
          this.emitter.emit('alert', {
            type: 'success',
            content: "L'utilisateur a bien été désactivé.",
          })
          this.emitter.emit('close-loader')
        },
        (responseError) => {
          this.emitter.emit('alert', {
            type: 'error',
            content: responseError.data,
          })
          this.emitter.emit('close-loader')
        },
      )
      this.modalDisableUser = false
    },
  },
}
</script>
